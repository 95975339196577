var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.$store.state.mobile)?_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.categories),function(category,i){return _c('v-btn',{key:i,staticClass:"mx-1 px-1 py-1",class:_vm.$store.state.categorySelected != category.name
          ? 'grey--text text--darken-2'
          : 'white--text text--darken-1',staticStyle:{"border-radius":"12px"},attrs:{"primary":"","small":"","dark":_vm.$store.state.categorySelected === category.name,"color":_vm.$store.state.categorySelected === category.name ? 'primary' : ''},on:{"click":function($event){return _vm.setSelected(category.name)}}},[_vm._v(" "+_vm._s(category.name)+" ")])}),1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-container',{staticClass:"text-center"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":"","width":"100"}},'v-btn',attrs,false),on),[(_vm.$store.state.categorySelected !== 'TUTTO')?_c('v-badge',{attrs:{"color":"none","light":"","overlap":"","bordered":"","icon":"mdi-check-bold"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-view-grid ")])],1):_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-view-grid ")])],1),_c('br'),_c('span',{staticClass:"text-caption my-2"},[_vm._v("CATEGORIE")])],1)]}}])},[_c('v-list',_vm._l((_vm.categories),function(category,i){return _c('v-list-item',{key:i},[_c('v-btn',{staticClass:"mx-1 px-1 py-1",class:_vm.$store.state.categorySelected != category.name
              ? 'grey--text text--darken-2'
              : 'indigo--text text--darken-1',attrs:{"primary":"","small":""},on:{"click":function($event){return _vm.setSelected(category.name)}}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }